import { FC, Suspense } from 'react'
import { getTreatments } from '@splitsoftware/splitio-redux'
import { ITemplate } from '../interfaces/templates'
import Cart from '../templates/Cart'
import Catalog from '../templates/Catalog'
import Categories from '../templates/Categories'
import Default from '../templates/Default'
import Filters from '../templates/Filters'
import CategoriesNav from '../templates/CategoriesNav'
import { useAppDispatch, useAppSelector } from '../hooks'
import { SPLIT_NAMES } from '../consts/splitio'
import Summary from '../templates/Summary'
import ProductDetail from '../templates/ProductDetail'

const BaseContainer: FC<ITemplate> = ({ container, ...props }) => {
  const dispatch = useAppDispatch()
  const { type } = container
  const sessionData = useAppSelector((state) => state.defaultSlice.sessionData)
  const storeName = useAppSelector((state) => state.defaultSlice.storeName)
  const [phoneNumber] = sessionData?.customer?.phoneNumber || []
  const email = sessionData?.customer?.email
  const contextAttributes = {
    email,
    phoneNumber,
  }

  dispatch(
    getTreatments({
      splitNames: SPLIT_NAMES,
      attributes: contextAttributes,
      key: storeName || '',
    }),
  )

  const getTemplate = () => {
    switch (type) {
      case 'cart':
        return <Cart container={container} {...props} />
      case 'catalog':
        return <Catalog container={container} {...props} />
      case 'filters':
        return <Filters container={container} {...props} />
      case 'categories':
        return <Categories container={container} {...props} />
      case 'summary':
        return <Summary container={container} {...props} />
      case 'categoriesnav':
        return <CategoriesNav container={container} {...props} />
      case 'productdetail':
        return <ProductDetail container={container} {...props} />
      default:
        return <Default container={container} {...props} />
    }
  }

  return <Suspense fallback={<div />}>{getTemplate()}</Suspense>
}

export default BaseContainer
