import { ActivePromotion, PromotionType } from '../../graphQL/commons'
import {
  IConfig,
  IPromotion,
  IPromotionUiCatalog,
  IVolumePromotionDetail,
  IVolumeResume,
} from '../../interfaces'
import { getPromotionFields } from '../common/getPromotionFields'
import formatPercentage from '../formatPercentage'
import formatPrice from '../formatPrice'
import formatPromotionMessage from '../formatPromotionMessage'
import sortByKey from '../sort'

export interface IGetVolumePromotions {
  config?: IConfig
  activePromotions: ActivePromotion[]
  promotions: IPromotion[]
  productPrice: number
}

export interface IGetVolumePromotionsResult {
  resume: IVolumeResume
  promotionDetails: IPromotionUiCatalog
  promotions: IPromotion[]
}

export const MIN_VALID_PROMOTIOS = 1
export const QUANTITY_PLACEHOLDER = '{{quantity}}'
export const ID_FOR_NON_SELECTED_PROMOTION = 'volume-promotion-non-selected'
export const QUANTITY_SEPARATOR = '-'
export const DEFAULT_QUANTITY = 1
export const MORE_PRODUCTS_SIGN = '+'

export const conditionFilterVolumePromotions = ({
  type,
  customerGets: { value },
}: IPromotion) => type === PromotionType.VOLUME && !!value?.percentage

export const getVolumePromotionsResume = ({
  config,
  activePromotions,
  productPrice,
  promotions,
}: IGetVolumePromotions): IGetVolumePromotionsResult | undefined => {
  const volumePromotions = promotions.filter(conditionFilterVolumePromotions)

  if (volumePromotions.length >= MIN_VALID_PROMOTIOS) {
    const sortedPromotions = sortByKey<IPromotion>(
      volumePromotions,
      'priority',
      true,
    )
    const firstPromotion = sortedPromotions[0]

    const currency: string | undefined =
      config?.options?.currency || config?.options?.currencySymbol
    const format: string | undefined = config?.options?.format

    const volumePromotionsDetails =
      sortedPromotions.map<IVolumePromotionDetail>((promotion, index) => {
        const { customerGetsPercentage, customerBuysMinimum } =
          getPromotionFields(promotion)

        const promotionPercentageFormat =
          config?.options?.promotionPercentageFormat
        const percentageLabel = formatPercentage(
          customerGetsPercentage,
          promotionPercentageFormat,
        )

        const savings = formatPromotionMessage({
          promotion,
          placeholders: {
            discount: percentageLabel,
          },
          config,
          message: config?.texts.promotions?.directIndividualLabel || '',
        })

        const unformattedPrice = productPrice * (1 - customerGetsPercentage)
        const price = formatPrice({
          currency,
          format,
          price: unformattedPrice,
        })

        const nextPromotion = sortedPromotions[index + 1]

        const quantity = nextPromotion
          ? `${customerBuysMinimum}${QUANTITY_SEPARATOR}${getPromotionFields(nextPromotion).customerBuysMinimum - 1}`
          : `${customerBuysMinimum}${MORE_PRODUCTS_SIGN}`

        return {
          id: promotion.id || '',
          quantity,
          savings,
          price,
        }
      })

    const formattedProductPrice = formatPrice({
      currency,
      format,
      price: productPrice,
    })
    const { customerBuysMinimum } = getPromotionFields(firstPromotion)

    const rowZero: IVolumePromotionDetail = {
      id: ID_FOR_NON_SELECTED_PROMOTION,
      quantity: `${DEFAULT_QUANTITY}${QUANTITY_SEPARATOR}${customerBuysMinimum - 1}`,
      price: formattedProductPrice,
    }

    const selectedPromotion = volumePromotionsDetails.find(
      ({ id: currentVolumePromotion }) =>
        activePromotions.some(
          ({ promotion: { id: currentActivePromotionId } }) =>
            currentActivePromotionId === currentVolumePromotion,
        ),
    )

    const promotionsResume = [rowZero, ...volumePromotionsDetails]

    const title = config?.texts.promotions?.volumeResumeLabel || ''
    const unformattedDescription =
      config?.texts.promotions?.volumeResumeDescription || ''
    const description = unformattedDescription.replace(
      QUANTITY_PLACEHOLDER,
      String(customerBuysMinimum),
    )

    const nonVolumePromotions = promotions.filter(
      (promotion) => !conditionFilterVolumePromotions(promotion),
    )
    return {
      resume: {
        selectedId: selectedPromotion?.id || ID_FOR_NON_SELECTED_PROMOTION,
        promotions: promotionsResume,
      },
      promotionDetails: {
        label: '',
        showIcon: true,
        title,
        description,
      },
      promotions: nonVolumePromotions,
    }
  }
  return
}

export default getVolumePromotionsResume
