import { useCallback, useEffect } from 'react'
import { ApolloError } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import ReactGA from 'react-ga4'
import Hotjar from '@hotjar/browser'
import type { ICartProduct } from '../../interfaces'
import type { ICounterActionArgs } from '../../interfaces/actions'
import type { IPrefillCartResponse } from '../../graphQL/queries/prefillCart/types'
import type { ILastOrderResponse } from '../../graphQL/queries/lastOrder/types'
import type { IPrefillCartMutationResponse } from '../../graphQL/mutations/prefillCart/types'
import { PrefillCartValues } from '../../graphQL/queries/getSession/types'
import { useLazyGetPrefillCart } from '../../graphQL/queries/prefillCart'
import { useLazyGetLastOrder } from '../../graphQL/queries/lastOrder'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import { generateDataDogContext } from '../../utils/dataDog'
import { getFlattedCartItems } from '../../utils/utilsForCatalogReducer'
import { sendGoogleEvent } from '../../utils/sendGoogleAnalytics'
import { setPromotionUiDetailsCatalog } from '../../utils/catalog/setPromotionUiDetailsCatalog'
import { addAlert, closeModal, openModal } from '../../redux/slices/config'
import {
  setPrefillCart,
  setPrefillCartTitle,
  updatePrefillCart,
} from '../../redux/slices/cart'
import {
  DefaultModalContainerNames,
  PREFILL_CART_LIMIT,
  Referrers,
} from '../../consts/defaultConfigValues/defaultConstants'
import { useAddPrefillCart } from './useAddPrefillCart'
import {
  castIGroupsToICartGroups,
  castItemsToICartProducts,
} from '../../utils/sanitizeCartProducts'

type Source = PrefillCartValues.ML | PrefillCartValues.LAST_ORDER

const sourceData = {
  [PrefillCartValues.ML]: {
    name: 'prefill cart',
    callbackName: 'prefillCart',
    hookName: 'useLazyGetPrefillCart',
  },
  [PrefillCartValues.LAST_ORDER]: {
    name: 'last order',
    callbackName: 'lastOrder',
    hookName: 'useLazyGetLastOrder',
  },
}

export const usePrefillCart = () => {
  const dispatch = useAppDispatch()
  const { addPrefillCart } = useAddPrefillCart()

  const config = useAppSelector((state) => state.defaultSlice.config)
  const storefrontName = useAppSelector(
    (state) => state.defaultSlice.storeName || '',
  )
  const sessionUid = useAppSelector(
    (state) => state.defaultSlice.sessionId || '',
  )
  const cartItemsTotal = useAppSelector((state) => state.cartSlice.itemsTotal)
  const prefillCartWasShown = useAppSelector(
    (state) => state.cartSlice.prefillCart.wasShown,
  )
  const prefillCartItems = useAppSelector(
    (state) => state.cartSlice.prefillCart.items,
  )
  const prefillCartTexts = useAppSelector(
    (state) => state.defaultSlice.config?.texts.prefillCart,
  )
  const prefillCartGlobals = useAppSelector(
    (state) => state.defaultSlice.config?.globals?.prefillCart,
  )
  const prefillCartValue =
    useAppSelector(
      (state) =>
        state.defaultSlice.sessionData?.configuration?.recommendedProducts
          ?.prefillCart,
    ) || PrefillCartValues.NONE
  /**
   * Sets prefill cart items in state and opens modal
   */
  const openPrefillCartModal = (items: ICartProduct[]) => {
    if (items.length) {
      const updatedItems = items.map((item) => {
        const promotion = setPromotionUiDetailsCatalog({
          product: item,
          config,
        })
        const price = item.package?.price || item.price
        const stock = item.package?.stock || item.stock
        return { ...item, promotion, price, stock }
      })

      dispatch(setPrefillCart(updatedItems))
      dispatch(
        openModal({ container: DefaultModalContainerNames.PREFILL_CART }),
      )
      ReactGA.send({
        hitType: 'pageview',
        page: `/${DefaultModalContainerNames.PREFILL_CART}`,
      })
    }
  }

  /**
   * Handles onComplete callback from prefill cart query
   */
  const onCompletePrefillCart = ({
    prefillCart = [],
  }: IPrefillCartResponse) => {
    const cartProducts = castItemsToICartProducts(prefillCart)
    dispatch(setPrefillCartTitle(prefillCartTexts?.suggestedTitle as string))
    openPrefillCartModal(cartProducts)
  }

  /**
   * Handles onComplete callback from last order query
   */
  const onCompleteLastOrder = ({
    lastOrder: { items, groups },
  }: ILastOrderResponse) => {
    const castedItems = castItemsToICartProducts(items)
    const castedGroups = castIGroupsToICartGroups(groups)
    const { flattedCartItems } = getFlattedCartItems({
      items: castedItems,
      groups: castedGroups,
    })
    dispatch(setPrefillCartTitle(prefillCartTexts?.lastOrderTitle as string))
    openPrefillCartModal(flattedCartItems)
  }

  /**
   * Executes callback when source is ML or last order
   */
  const onError = (error: ApolloError, source: Source) => {
    const { name, hookName } = sourceData[source]
    const context = generateDataDogContext({
      title: `could not fetch ${name}} response`,
      extraInfo: { function: hookName },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  /**
   * Handles error from prefill cart query
   */
  const onErrorPrefillCart = (error: ApolloError) => {
    onError(error, PrefillCartValues.ML)
  }

  /**
   * Handles error from last order query
   */
  const onErrorLastOrder = (error: ApolloError) => {
    onError(error, PrefillCartValues.LAST_ORDER)
  }
  /**
   * Get prefillCart lazy hook
   */
  const [getPrefillCart] = useLazyGetPrefillCart({
    onCompleted: onCompletePrefillCart,
    onError: onErrorPrefillCart,
  })
  /**
   * Get lastOrder lazy hook
   */
  const [getLastOrder] = useLazyGetLastOrder({
    onCompleted: onCompleteLastOrder,
    onError: onErrorLastOrder,
  })

  /**
   * Fetches items from ML or last order
   */
  const getItems = useCallback(
    async (source: Source) => {
      try {
        if (source === PrefillCartValues.ML) {
          await getPrefillCart({
            variables: {
              storefrontName,
              sessionUid,
              limit: prefillCartGlobals?.limit || PREFILL_CART_LIMIT,
            },
          })
        }
        if (source === PrefillCartValues.LAST_ORDER) {
          await getLastOrder({
            variables: {
              storefrontName,
              sessionUid,
            },
          })
        }
      } catch (exception) {
        const { name, callbackName } = sourceData[source]
        const context = generateDataDogContext({
          title: `could not fetch ${name} cart items`,
          extraInfo: { function: `${callbackName}` },
        })
        datadogRum.startView(context.viewName)
        datadogRum.addError(exception, context)
      }
    },
    [
      storefrontName,
      sessionUid,
      prefillCartGlobals?.limit,
      getPrefillCart,
      getLastOrder,
    ],
  )

  const validatePrefillCart = async () => {
    if (
      prefillCartValue === PrefillCartValues.NONE ||
      prefillCartWasShown ||
      cartItemsTotal > 0
    ) {
      return
    }
    await getItems(prefillCartValue)
  }

  /**
   * Checks if prefill cart is enabled or was already shown or cart is empty
   */
  useEffect(() => {
    validatePrefillCart()
  }, [prefillCartValue, prefillCartWasShown, cartItemsTotal, getItems])

  /**
   * Updates prefill cart item and general data
   */
  const updatePrefillCartItem = (data: ICounterActionArgs) => {
    const { sku, newValue, value } = data
    const eventAction = newValue > value ? 'Add' : 'Remove'
    const actionLabel = `${eventAction} to prefill cart from ${Referrers.PREFILL_CART}`
    dispatch(updatePrefillCart(data))
    Hotjar.event(actionLabel)
    sendGoogleEvent('cart', actionLabel, sku, newValue)
  }

  /**
   * Handles onComplete callback from prefill cart mutation
   */
  const onCompletePrefillCartMutation = (
    data: IPrefillCartMutationResponse,
  ) => {
    if (data?.prefillCart) {
      dispatch(
        addAlert({
          message: prefillCartTexts?.successMessage as string,
          severity: 'success',
        }),
      )
    }
    dispatch(closeModal({ container: DefaultModalContainerNames.PREFILL_CART }))
    const actionLabel = `Add prefill cart from modal`
    Hotjar.event(actionLabel)
    sendGoogleEvent('cart', actionLabel, prefillCartValue)
  }

  /**
   * Adds prefill cart items to cart
   */
  const addPrefillCartToCart = async () => {
    await addPrefillCart({
      items: prefillCartItems,
      storefrontName,
      sessionUid,
      onCompleted: onCompletePrefillCartMutation,
    })
  }

  return {
    updatePrefillCartItem,
    addPrefillCartToCart,
    validatePrefillCart,
  }
}
